// Libraries
import FlatList from '@react-x/flat-list';
import _ from 'lodash';
import React from 'react';
// @ts-expect-error library is not typed
import {Document, Page} from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import './AnnotationLayer.css';

// Supermove
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Space from '../Space';
import Styled from '../Styled';

import {PdfViewV2Props} from './PdfViewV2Types';

const Container = Styled.View`
  flex: 1;
`;

const PageContainer = Styled.View<{pagePadding: number}>`
  padding-vertical: ${({pagePadding}) => pagePadding}px;
  padding-horizontal: ${({pagePadding}) => pagePadding}px;
  background-color: ${colors.white};
`;

const LoadingContainer = Styled.View`
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Indicator = Styled.Loading`
`;

const LoadingIndicator = () => (
  <LoadingContainer>
    <Indicator size={'large'} color={colors.gray.secondary} />
  </LoadingContainer>
);

const PdfViewV2 = ({
  source,
  onLoad = () => {},
  pageWidth = PAGE_WIDTH,
  pagePadding = 36,
  LoadingComponent,
}: PdfViewV2Props) => {
  const [pagesCount, setPagesCount] = useState(0);
  const pageNumbers = _.range(pagesCount);

  return (
    <Container>
      <Document
        file={source.uri}
        onLoadSuccess={(pdf: {numPages: number}) => {
          setPagesCount(pdf.numPages);
          onLoad({numberOfPages: pdf.numPages});
        }}
        // overriding noData prevents flashing message "No PDF file specified"
        noData={() => {}}
        loading={LoadingComponent || LoadingIndicator}
      >
        <FlatList
          listKey={'pdf-view-v2'}
          initialNumToRender={1}
          data={pageNumbers}
          keyExtractor={(number, index) => String(index)}
          renderItem={({item, index}) => {
            return (
              <PageContainer pagePadding={pagePadding}>
                <Page pageNumber={index + 1} width={pageWidth} />
              </PageContainer>
            );
          }}
          ItemSeparatorComponent={() => <Space height={36} />}
        />
      </Document>
    </Container>
  );
};

// Standard A4 dimensions
const PAGE_HEIGHT = 841.89;
const PAGE_WIDTH = 595.28;
PdfViewV2.PAGE_ASPECT_RATIO = PAGE_HEIGHT / PAGE_WIDTH;
PdfViewV2.PAGE_HEIGHT = PAGE_HEIGHT;

export default PdfViewV2;
