/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
// Libraries
import React from 'react';

// Supermove
import {useResponsive, useEffect, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import Icon, {IconSource} from '../Icon';
import Space from '../Space';
import Styled from '../Styled';

const ICON_COLOR = colors.blue.interactive;
const PLAY_PAUSE_ICON_SIZE = 40;
const FORWARD_BACKWARD_ICON_SIZE = 20;
const AUDIO_SPEEDS = [1, 1.5, 2];

const Container = Styled.View`
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const ButtonContainer = Styled.ButtonV2`
`;

const formatTime = ({seconds}: {seconds: number}) => {
  return new Date(seconds * 1000).toISOString().slice(14, 19);
};

interface IconButtonProps {
  icon: IconSource;
  size?: number;
  color?: string;
  onPress?: () => void;
}

const IconButton = ({icon, size, color, onPress}: IconButtonProps) => {
  return (
    <ButtonContainer onPress={onPress}>
      <Icon source={icon} size={size} color={color} />
    </ButtonContainer>
  );
};

interface AudioSpeedButtonProps {
  audioSpeedIndex: number;
  onPress?: () => void;
  style?: React.CSSProperties;
}

const AudioSpeedButton = ({audioSpeedIndex, onPress, style}: AudioSpeedButtonProps) => {
  const responsive = useResponsive();
  return (
    <ButtonContainer onPress={onPress}>
      <LabelText
        responsive={responsive}
        style={{
          color: colors.blue.interactive,
          ...style,
        }}
      >
        {`${AUDIO_SPEEDS[audioSpeedIndex]}x`}
      </LabelText>
    </ButtonContainer>
  );
};

interface SliderProps {
  sliderValue: number;
  audioDuration: number;
  onSliderChange: (arg: {newValue: number}) => void;
}

const Slider = ({sliderValue, audioDuration, onSliderChange}: SliderProps) => {
  return (
    <input
      type='range'
      step={1}
      min={0}
      max={audioDuration}
      value={sliderValue}
      onChange={(event) => onSliderChange({newValue: Number(event.target.value)})}
      style={{alignSelf: 'stretch'}}
    />
  );
};

interface ControlsProps {
  isPlaying: boolean;
  setIsPlaying: (newValue: boolean) => void;
  skipForward: () => void;
  skipBackward: () => void;
}

const Controls = ({isPlaying, setIsPlaying, skipForward, skipBackward}: ControlsProps) => {
  const responsive = useResponsive();
  return (
    <Row>
      <MicroLabelText responsive={responsive}>15s</MicroLabelText>
      <Space width={8} />
      <IconButton
        icon={Icon.ArrowRotateLeft}
        size={FORWARD_BACKWARD_ICON_SIZE}
        color={ICON_COLOR}
        onPress={skipBackward}
      />
      <Space width={24} />
      {isPlaying ? (
        <IconButton
          icon={Icon.CirclePause}
          size={PLAY_PAUSE_ICON_SIZE}
          color={ICON_COLOR}
          onPress={() => setIsPlaying(false)}
        />
      ) : (
        <IconButton
          icon={Icon.CirclePlay}
          size={PLAY_PAUSE_ICON_SIZE}
          color={ICON_COLOR}
          onPress={() => setIsPlaying(true)}
        />
      )}
      <Space width={24} />
      <IconButton
        icon={Icon.ArrowRotateRight}
        size={FORWARD_BACKWARD_ICON_SIZE}
        color={ICON_COLOR}
        onPress={skipForward}
      />
      <Space width={8} />
      <MicroLabelText responsive={responsive}>15s</MicroLabelText>
    </Row>
  );
};

interface AudioPlayerProps {
  audioSrc: string;
  onCurrentTimeChange: (args: {currentTime: number}) => void;
  isPlaying: boolean;
  setIsPlaying: (newValue: boolean) => void;
}

const AudioPlayer = React.forwardRef<HTMLAudioElement, AudioPlayerProps>(
  ({audioSrc, onCurrentTimeChange, isPlaying, setIsPlaying}, audioRef) => {
    const responsive = useResponsive();
    const [sliderValue, setSliderValue] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);
    const [audioSpeedIndex, setAudioSpeedIndex] = useState(0);

    // Load and monitor audio metadata
    useEffect(() => {
      // @ts-ignore
      if (!audioRef || !audioRef.current) return;

      // @ts-ignore
      const audioElem = audioRef.current;

      const handleLoadedMetadata = () => {
        setAudioDuration(audioElem.duration || 0);
      };

      const handleTimeUpdate = () => {
        setSliderValue(audioElem.currentTime);
        onCurrentTimeChange({currentTime: audioElem.currentTime});
      };

      const handleEnded = () => {
        setIsPlaying(false);
      };

      audioElem.addEventListener('loadedmetadata', handleLoadedMetadata);
      audioElem.addEventListener('timeupdate', handleTimeUpdate);
      audioElem.addEventListener('ended', handleEnded);

      return () => {
        audioElem.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audioElem.removeEventListener('timeupdate', handleTimeUpdate);
        audioElem.removeEventListener('ended', handleEnded);
      };
    }, [audioRef, onCurrentTimeChange, setIsPlaying]);

    useEffect(() => {
      // @ts-ignore
      if (!audioRef || !audioRef.current) return;
      if (isPlaying) {
        // @ts-ignore
        audioRef.current.play().catch((err) => {
          console.error('Play failed:', err);
          setIsPlaying(false);
        });
      } else {
        // @ts-ignore
        audioRef.current.pause();
      }
    }, [audioRef, isPlaying, setIsPlaying]);

    const onSliderChange = ({newValue}: {newValue: number}) => {
      // @ts-ignore
      if (!audioRef || !audioRef.current) return;
      // Optionally, auto-play if user drags
      // setIsPlaying(true);
      // @ts-ignore
      audioRef.current.currentTime = newValue;
      setSliderValue(newValue);
    };

    const skipForward = () => {
      // @ts-ignore
      if (!audioRef || !audioRef.current) return;
      // @ts-ignore
      const newTime = Math.min(audioDuration, audioRef.current.currentTime + 15);
      // @ts-ignore
      audioRef.current.currentTime = newTime;
      setSliderValue(newTime);
    };

    const skipBackward = () => {
      // @ts-ignore
      if (!audioRef || !audioRef.current) return;
      // @ts-ignore
      const newTime = Math.max(0, audioRef.current.currentTime - 15);
      // @ts-ignore
      audioRef.current.currentTime = newTime;
      setSliderValue(newTime);
    };

    const changeAudioSpeed = () => {
      // @ts-ignore
      if (!audioRef || !audioRef.current) return;

      const nextIndex = (audioSpeedIndex + 1) % AUDIO_SPEEDS.length;
      setAudioSpeedIndex(nextIndex);

      // @ts-ignore
      audioRef.current.playbackRate = AUDIO_SPEEDS[nextIndex];
    };

    return (
      <React.Fragment>
        <audio ref={audioRef} src={audioSrc} />
        <Container>
          <Slider
            sliderValue={sliderValue}
            audioDuration={audioDuration}
            onSliderChange={onSliderChange}
          />
          <Space height={8} />
          <MicroText responsive={responsive}>
            {`${formatTime({seconds: sliderValue})} / ${formatTime({seconds: audioDuration})}`}
          </MicroText>
          <Space height={16} />
          <Row style={{alignSelf: 'stretch', justifyContent: 'space-between'}}>
            <AudioSpeedButton audioSpeedIndex={audioSpeedIndex} onPress={changeAudioSpeed} />
            <Controls
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              skipForward={skipForward}
              skipBackward={skipBackward}
            />
            <IconButton
              icon={Icon.ArrowDownToBracket}
              size={16}
              color={ICON_COLOR}
              onPress={() => downloadFromUrl(audioSrc)}
            />
          </Row>
        </Container>
      </React.Fragment>
    );
  },
);

export default AudioPlayer;
