// Libraries
import {
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {sortableKeyboardCoordinates} from '@dnd-kit/sortable';
import _ from 'lodash';
import React from 'react';

// Supermove
import {useState} from '@supermove/hooks';

// Relative
import {OnReorderType} from '../components/DragAndDropBuilder';

const useDndKit = ({
  onReorder,
  children,
}: {
  onReorder: OnReorderType;
  children: React.ReactElement[];
}) => {
  const [isDraggingId, setIsDraggingId] = useState<number | string>();
  const childrenWithIds = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      id: child.key,
    });
  });

  const itemIds = React.Children.map(childrenWithIds, (child: {props: {id: string}}) => {
    return child.props.id;
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // 8px movement required before activation
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250, // Wait for 250ms before activation
        tolerance: 5, // Small tolerance for slight movement
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    const fromIndex = active.data.current?.sortable.index;
    const toIndex = over?.data.current?.sortable.index;

    if (!_.isNil(toIndex) && fromIndex !== toIndex) {
      onReorder({fromIndex, toIndex});
    }
    setIsDraggingId(undefined);
  };

  return {
    childrenWithIds,
    itemIds,
    isDragging: !_.isNil(isDraggingId),
    isDraggingId,
    setIsDraggingId,
    handleDragEnd,
    sensors,
  };
};

export default useDndKit;
