/* eslint-disable react/no-danger */
// @ts-expect-error library is not typed
import DOMPurify from 'dompurify';
import React, {useEffect, useRef, useState} from 'react';

// Supermove
import {useMemo} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const parseStylesAndBody = (rawHTML: string) => {
  let htmlStyles = '';
  let htmlBody = '';

  // Extract styles
  // Find start and end of style opening tag
  const styleTagStart = rawHTML.indexOf('<style');
  const cssStart = rawHTML.indexOf('>', styleTagStart) + 1;
  const cssEnd = rawHTML.indexOf('</style>');

  // We might not have a style tag
  if (styleTagStart !== -1 && cssStart !== 0 && cssEnd !== -1) {
    htmlStyles = `<style>${rawHTML.slice(cssStart, cssEnd)}</style>`;
  }

  // Extract html body
  // +6 for the length of <body>
  const bodyStart = rawHTML.indexOf('<body>') + 6;
  const bodyEnd = rawHTML.indexOf('</body>');

  // We might not have a body tag
  if (bodyStart !== 5 && bodyEnd !== -1) {
    htmlBody = rawHTML.slice(bodyStart, bodyEnd);
  } else {
    htmlBody = rawHTML;
  }

  return {htmlStyles, htmlBody};
};

const addATagsToLinks = (html: string) => {
  const urlRegex =
    /\b(?:(?:https?:\/\/|www\.)[^\s<]+|[a-zA-Z0-9.-]+\.(?:com|org|net|gov|edu|co|uk|ca|mx))\b/gi;

  return html.replace(urlRegex, (match, offset, fullText) => {
    const beforeText = fullText.slice(0, offset);

    // Check if we are inside an HTML tag (e.g. <img src="...">).
    // If the last `<` is after the last `>`, that means we haven't closed that tag yet.
    const openTagIndex = beforeText.lastIndexOf('<');
    const closeTagIndex = beforeText.lastIndexOf('>');
    if (openTagIndex > closeTagIndex) {
      // We are inside a tag — skip
      return match;
    }

    // Check if "match" is already inside an <a> tag
    const openAnchorIndex = beforeText.lastIndexOf('<a ');
    const closeAnchorIndex = beforeText.lastIndexOf('</a>');
    // If there's an unclosed <a> tag, skip
    if (openAnchorIndex > closeAnchorIndex) {
      return match;
    }

    // If it doesn't start with http/https, prepend https://
    let url = match;
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }

    // Return the anchor-wrapped URL
    return `<a href="${url}" target="_blank">${match}</a>`;
  });
};

const removeHeight100FromTables = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Grab all <table height="100%"> elements
  doc.querySelectorAll('table[height="100%"]').forEach((table) => {
    table.removeAttribute('height');
  });

  return doc.documentElement.outerHTML;
};

const SanitizedHTML = ({
  rawHTML,
  linkifyUrls,
  useAppFont,
  initialHeight = null,
}: {
  rawHTML: string;
  linkifyUrls?: boolean;
  useAppFont?: boolean;
  initialHeight?: number | null; // Initial height of the iframe, it will be minimum 150 if this is not set lower
}) => {
  const {htmlStyles, htmlBody} = parseStylesAndBody(rawHTML);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number | null | undefined>(initialHeight);

  // Sanitize the HTML and add a click handler to prevent links from opening
  // We need to recurse through all parent elements of an element to find one that may be a link,
  // since the click event may be triggered on a child element of the link that is a descendant of the <a> tag
  // Also, emails allow links with no protocol, but those are treated as relatives links in the preview
  const sanitizedHtml = useMemo(() => {
    const bodyWithSanitization = DOMPurify.sanitize(htmlBody);
    const bodyWithFixedLinks = linkifyUrls
      ? addATagsToLinks(bodyWithSanitization)
      : bodyWithSanitization;
    // If a table has height="100%", that can cause infinite scrolling
    // because the container expands to fit the content but the content also expands to fit the container
    const bodyWithNoInfiniteScroll = removeHeight100FromTables(bodyWithFixedLinks);
    return `<body>
      ${bodyWithNoInfiniteScroll}
    <script>
      document.addEventListener('click', function(event) {
        let target = event.target;
        while (target && target.tagName !== 'A') {
          target = target.parentElement;
        }
        if (target && target.tagName === 'A') {
          event.preventDefault();
          window.open(target.href, '_blank');
        }
      }, true);
    </script>
    </body>`;
  }, [htmlBody]);

  useEffect(() => {
    // Write the HTML into the iframe document
    const iframeDoc = iframeRef.current?.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.open();

      if (useAppFont) {
        const defaultFontStyle = `<style>
        html, body {
          font-family: 'Avenir';
          color: ${colors.gray.primary}
        }
      </style>`;
        iframeDoc.write(defaultFontStyle, htmlStyles, sanitizedHtml);
      } else {
        iframeDoc.write(htmlStyles, sanitizedHtml);
      }

      iframeDoc.close();
    }

    // Handle setting iframe height and retry to ensure content has loaded
    let retryCount = 0;
    const intervalId = setInterval(() => {
      const newHeight = iframeRef.current?.contentWindow?.document.body.scrollHeight;
      if (iframeHeight !== newHeight) {
        setIframeHeight(newHeight);
      }
      // Stop retrying after 10 attempts (2 seconds)
      retryCount += 1;
      if (retryCount === 10) {
        clearInterval(intervalId);
      }
    }, 200);
    return () => clearInterval(intervalId);
  }, [htmlStyles, iframeHeight, rawHTML, sanitizedHtml]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        // @ts-expect-error ts says this doesn't exist
        paddingVertical: '24px',
        justifyContent: 'center',
      }}
    >
      <iframe
        ref={iframeRef}
        style={{
          height: `${iframeHeight}px`,
          width: '100%',
          border: 'none',
        }}
        width='100%'
        title='Content container'
        sandbox='allow-downloads allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox'
        scrolling='no'
      />
    </div>
  );
};

export default SanitizedHTML;
