// Libraries
import Image from '@tiptap/extension-image';
import Link, {LinkOptions} from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

// Supermove

import {
  CustomBackgroundColor,
  CustomColor,
  CustomHeading,
  CustomFontSize,
  CustomListItem,
  CustomTextAlign,
  KeepClasses,
  CustomOrderedList,
  UpdateListNesting,
  CustomBulletList,
  UpdateListColor,
} from './customExtensions';
import {
  CustomColor as CustomColorV1,
  CustomListItem as CustomListItemV1,
  CustomOrderedList as CustomOrderedListV1,
  UpdateListNesting as UpdateListNestingV1,
} from './customExtensionsV1';

// Types affected by text align
const textAlignTypes = ['paragraph', 'heading', 'orderedList', 'bulletList'];

const LinkConfigureParams: Partial<LinkOptions> = {
  openOnClick: false,
  autolink: false,
  defaultProtocol: 'https',
};

export const getFormattingEditorExtensions = (
  placeholder?: string,
  hasOrderedListNumberColor?: boolean,
) => {
  const editorExtensions = [
    // We provide a custom list item, disable the default one
    StarterKit.configure({listItem: false, heading: false, orderedList: false, bulletList: false}),
    hasOrderedListNumberColor ? CustomListItem : CustomListItemV1,
    // Email clients like gmail don't support base64 images, so don't let users add them
    CustomHeading,
    CustomBulletList,
    hasOrderedListNumberColor ? CustomOrderedList : CustomOrderedListV1,
    Image.configure({inline: true, allowBase64: false}),
    KeepClasses,
    // TextStyle must come before CustomColor because Color extends TextStyle
    TextStyle,
    hasOrderedListNumberColor ? CustomColor : CustomColorV1,
    Link.configure(LinkConfigureParams),
    CustomFontSize,
    CustomTextAlign.configure({types: textAlignTypes}),
    CustomBackgroundColor,
    Underline,
    Placeholder.configure({placeholder}),
    hasOrderedListNumberColor ? UpdateListNesting : UpdateListNestingV1,
    ...(hasOrderedListNumberColor ? [UpdateListColor] : []),
  ];

  return editorExtensions;
};
