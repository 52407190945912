// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

import Styled from '../../Styled';

export type OnReorderType = (params: {fromIndex: number; toIndex: number}) => void;

const ItemContainer = Styled.View<{
  index: number;
  spaceBetweenItems: number;
  isHorizontal?: boolean;
}>`
  flex-direction: row;
  align-items: center;
  flex: 1;
  z-index: ${({index}) => 5000 - index};
  margin-vertical: ${({spaceBetweenItems, isHorizontal}) => (isHorizontal ? 0 : spaceBetweenItems / 2)}px;
  margin-horizontal: ${({spaceBetweenItems, isHorizontal}) => (isHorizontal ? spaceBetweenItems / 2 : 0)}px;
`;

const DragIcon = ({isDisabled, hasMargin = true}: {isDisabled?: boolean; hasMargin?: boolean}) => {
  return (
    <Icon
      source={Icon.GripVertical}
      color={isDisabled ? colors.gray.tertiary : colors.gray.secondary}
      size={Icon.Sizes.Large}
      style={{marginRight: hasMargin ? 10 : 0}}
    />
  );
};

export default {
  ItemContainer,
  DragIcon,
};
