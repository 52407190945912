// Libraries
import {Color} from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import {OrderedList} from '@tiptap/extension-ordered-list';
import {Extension} from '@tiptap/react';
import {Plugin} from 'prosemirror-state';

// Supermove
import {markTypes} from './markTypes';

const orderedListStyles = `font-size: 14px; line-height: 1.25; color: #212121; margin-top: 5px;`;

// Makes the nested lists display properly with indented items correctly going to "a", "i" etc
const listTypes = ['decimal', 'lower-alpha', 'lower-roman', 'decimal'];

export const CustomOrderedList = OrderedList.extend({
  addAttributes() {
    return {
      dataLevel: {
        default: 1,
        parseHTML: (element) => parseInt(element.getAttribute('data-level') ?? '1', 10) || 1,
        renderHTML: (attributes) => ({
          'data-level': attributes.dataLevel,
        }),
      },
    };
  },
  renderHTML({node, HTMLAttributes}) {
    const level = node.attrs.dataLevel || 1;

    // Define list-style types based on the nesting level
    const listStyleType = listTypes[(level - 1) % listTypes.length];

    const style = `list-style-type: ${listStyleType}; ${orderedListStyles}`;

    return ['ol', {style, ...HTMLAttributes}, 0];
  },
});

export const UpdateListNesting = Extension.create({
  name: 'updateListNesting',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          let {tr} = newState;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (node.type.name === 'orderedList') {
              // Start with level 1 for top-level lists
              let level = 1;

              // Resolve the position to get a ResolvedPos object
              const $pos = tr.doc.resolve(pos);

              // Iterate over the depth to calculate nesting level
              for (let depth = $pos.depth - 1; depth >= 0; depth -= 1) {
                const parentNode = $pos.node(depth);
                if (parentNode.type.name === 'orderedList') {
                  level += 1;
                }
              }

              if (node.attrs.dataLevel !== level) {
                tr = tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  dataLevel: level,
                });
                modified = true;
              }
            }
          });

          if (modified) {
            return tr;
          }
          return null;
        },
      }),
    ];
  },
});

// Adds font size support to list items
export const CustomListItem = ListItem.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: null,
        parseHTML: (element) => element.style.fontSize || null,
        renderHTML: (attributes) => {
          if (!attributes.fontSize) {
            return {};
          }
          return {
            style: `font-size: ${attributes.fontSize ?? '14px'}; color: #212121; line-height: 1.25; margin-top: 5px;`,
          };
        },
      },
    };
  },
  renderHTML({node, HTMLAttributes}) {
    const parentLevel = node.attrs.dataLevel || 1;

    // Modify child nodes by passing down the incremented data level
    const attrs = {
      ...HTMLAttributes,
      'data-level': parentLevel,
    };

    return ['li', attrs, 0];
  },
});

// Makes color work within all marks, including bold, italic, etc.
export const CustomColor = Color.configure({
  types: markTypes,
});
