// Libraries
import _ from 'lodash';
import qs from 'qs';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {SupermoveNavigatorType} from '../NavigationTypes';

const getSearchParams = ({location}: {location: SupermoveNavigatorType['location']}) => {
  const search = _.replace(location.search, '?', '');
  return qs.parse(search);
};

const useNavigation = (): {navigator: SupermoveNavigatorType; params: Record<string, any>} => {
  const history = useHistory() as unknown as SupermoveNavigatorType;
  const location = useLocation();
  const params = useParams();

  return {
    navigator: {
      ...history,
      pushNewTab: (url: string) => window.open(url),
      // Bit of a hacky way of determining if the user can go back https://stackoverflow.com/a/70532858
      // If we switch to React Navigation, there's a built in method for this.
      canGoBack: () => !!history.location?.key,
    },
    params: {
      ...getSearchParams({location}),
      ...params,
    },
  };
};

export default useNavigation;
