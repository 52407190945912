// Libraries
import {BulletList} from '@tiptap/extension-bullet-list';
import {Color} from '@tiptap/extension-color';
import {Heading} from '@tiptap/extension-heading';
import ListItem from '@tiptap/extension-list-item';
import {OrderedList} from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';
import {Extension, ChainedCommands} from '@tiptap/react';
import {Plugin} from 'prosemirror-state';

// Supermove
import {markTypes} from './markTypes';

// Adds the custom functions we declare as part of the tiptap TS commands
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (size: string) => ReturnType;
      unsetFontSize: () => ReturnType;
    };
    setBackgroundColor: {
      setBackgroundColor: (color: string) => ReturnType;
      unsetBackgroundColor: () => ReturnType;
    };
  }
}

// Adds font size support
export const CustomFontSize = Extension.create({
  name: 'fontSize',
  addOptions() {
    return {
      types: markTypes,
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => {
              const fontSize = element.style.fontSize.replace(/['"]+/g, '');
              return fontSize || null;
            },
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }
              const {fontSize} = attributes;
              // Fixes the issue where span elements in p tags were using the line height of the p tag
              return {
                style: `font-size: ${fontSize}; display: inline-block; vertical-align: middle;`,
              };
            },
          },
        },
      },
    ];
  },
  // Sets the font size, if it's an OL list item make sure to update the item marker too
  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({chain, state}) => {
          const {selection} = state;
          const {$from} = selection;

          // Find the closest parent listItem node
          let {depth} = $from;
          let found = false;
          for (; depth > 0; depth -= 1) {
            const node = $from.node(depth);
            if (node.type.name === 'listItem') {
              found = true;
              break;
            }
          }

          if (found) {
            const pos = $from.before(depth);
            return (
              chain()
                // Apply font size to text
                .setMark('textStyle', {fontSize})
                // Update attributes of the current list item only
                .command(({tr}) => {
                  const node = $from.node(depth);
                  tr.setNodeMarkup(pos, node.type, {
                    ...node.attrs,
                    fontSize,
                  });
                  return true;
                })
                .run()
            );
          } else {
            // Not inside a list item, just set font size on text
            return chain().setMark('textStyle', {fontSize}).run();
          }
        },

      unsetFontSize:
        () =>
        ({chain}) => {
          return chain().setMark('textStyle', {fontSize: null}).removeEmptyTextStyle().run();
        },
    };
  },
});

// These should be kept in sync with the RichTextInput.css, the RichText.css, and the RichText.ts file.
// If we can ever fully transition all documents to the new way, the other CSS files can remove their styles and we can fully rely on this
const headerStyles = {
  1: 'font-family: Avenir; font-size: 18px; color: #212121; font-weight: 700; margin-top: 24px',
  2: 'font-family: Avenir; font-size: 14px; color: #616161; font-weight: 700; text-transform: uppercase; letter-spacing: 1px;',
} as const;
const bulletListStyles = `font-size: 14px; line-height: 1.25; color: #212121; margin-top: 5px;`;
const orderedListStyles = `font-size: 14px; line-height: 1.25; color: #212121; margin-top: 5px;`;

const isHeaderKey = (key: number | string): key is keyof typeof headerStyles => {
  return !!(key && key in headerStyles);
};

export const CustomHeading = Heading.extend({
  renderHTML({node, HTMLAttributes}) {
    const {level} = node.attrs;
    const style = isHeaderKey(level) ? headerStyles[level] : '';
    return [`h${level}`, {style, ...HTMLAttributes}, 0];
  },
});

export const CustomBulletList = BulletList.extend({
  renderHTML({node, HTMLAttributes}) {
    return ['ul', {bulletListStyles, ...HTMLAttributes}, 0];
  },
});

// Makes the nested lists display properly with indented items correctly going to "a", "i" etc
const listTypes = ['decimal', 'lower-alpha', 'lower-roman', 'decimal'];

export const CustomOrderedList = OrderedList.extend({
  addAttributes() {
    return {
      dataLevel: {
        default: 1,
        parseHTML: (element) => parseInt(element.getAttribute('data-level') ?? '1', 10) || 1,
        renderHTML: (attributes) => ({
          'data-level': attributes.dataLevel,
        }),
      },
      // Add textColor attribute for ordered lists
      textColor: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-text-color') || element.style.color || null,
        renderHTML: (attributes) => {
          if (!attributes.textColor) {
            return {};
          }
          // Include it both as data attribute and direct style
          return {
            'data-text-color': attributes.textColor,
            'style': `--marker-color: ${attributes.textColor};`, // CSS variable for potential usage
          };
        },
      },
    };
  },
  renderHTML({node, HTMLAttributes}) {
    const level = node.attrs.dataLevel || 1;
    const {textColor} = node.attrs;

    // Define list-style types based on the nesting level
    const listStyleType = listTypes[(level - 1) % listTypes.length];

    // Start with base styles
    let styleStr = `list-style-type: ${listStyleType}; ${orderedListStyles}`;

    // Add color to list if textColor is present
    // This is the most email-client friendly way to style list markers
    if (textColor) {
      styleStr += `; color: ${textColor};`;

      // Add data attribute for potential additional targeting
      HTMLAttributes['data-text-color'] = textColor;
    }

    return [
      'ol',
      {
        ...HTMLAttributes,
        style: styleStr,
      },
      0,
    ];
  },
});

// Adds font size support to list items
export const CustomListItem = ListItem.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: null,
        parseHTML: (element) => element.style.fontSize || null,
        renderHTML: (attributes) => {
          if (!attributes.fontSize) {
            return {};
          }
          return {
            style: `font-size: ${attributes.fontSize ?? '14px'}; color: #212121; line-height: 1.25; margin-top: 5px;`,
          };
        },
      },
      // Add inheritance of textColor from parent list
      textColor: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-text-color') || element.style.color || null,
        renderHTML: (attributes) => {
          if (!attributes.textColor) {
            return {};
          }
          return {
            'data-text-color': attributes.textColor,
            'style': `color: ${attributes.textColor};`, // Inline style for email clients
          };
        },
      },
    };
  },
  renderHTML({node, HTMLAttributes}) {
    const parentLevel = node.attrs.dataLevel || 1;
    const {textColor} = node.attrs;

    // Start with the base attributes
    const attrs = {
      ...HTMLAttributes,
      'data-level': parentLevel,
    };

    // If we have a text color, add it to the data and style
    if (textColor) {
      return [
        'li',
        {
          ...attrs,
          'data-text-color': textColor,
          'style': `color: ${textColor};`,
        },
        0,
      ];
    }

    return ['li', attrs, 0];
  },
});

// Applies the background color from quill to the style of tiptap
// Makes background color work within all marks, including bold, italic, etc.
export const CustomBackgroundColor = Extension.create({
  name: 'backgroundColor',

  addOptions() {
    return {
      types: markTypes,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types, // Apply to the specified types
        attributes: {
          backgroundColor: {
            default: null,
            parseHTML: (element) => {
              const {backgroundColor} = element.style;
              return backgroundColor || null;
            },
            renderHTML: (attributes) => {
              if (!attributes.backgroundColor) {
                return {};
              }
              return {
                style: `background-color: ${attributes.backgroundColor};`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBackgroundColor:
        (backgroundColor: string) =>
        ({chain}: {chain: () => ChainedCommands}) => {
          return chain().setMark('textStyle', {backgroundColor}).run();
        },
      unsetBackgroundColor:
        () =>
        ({chain}: {chain: () => ChainedCommands}) => {
          return chain().setMark('textStyle', {backgroundColor: null}).removeEmptyTextStyle().run();
        },
    };
  },
});

// Makes color work within all marks, including bold, italic, etc.
export const CustomColor = Color.configure({
  types: markTypes,
}).extend({
  // Override the setColor command to also update list item markers when appropriate
  addCommands() {
    return {
      setColor:
        (color) =>
        ({chain, state}) => {
          const {selection} = state;
          const {$from, $to} = selection;

          // Find all ordered lists and list items in the selection
          const orderedLists: {pos: number; node: any}[] = [];
          const listItems: {pos: number; node: any; fullySelected: boolean}[] = [];

          // Check if we have ordered lists within our selection
          state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
            if (node.type.name === 'orderedList') {
              // Store the list for later processing
              orderedLists.push({pos, node});
            }
            if (node.type.name === 'listItem') {
              // Check if the entire list item is selected
              const nodeStart = pos;
              const nodeEnd = pos + node.nodeSize;
              const fullySelected = $from.pos <= nodeStart && $to.pos >= nodeEnd;

              // Store list item with selection status
              listItems.push({pos, node, fullySelected});
            }
            // Continue traversing
            return true;
          });

          // First apply the color to the text
          const colorChain = chain().setMark('textStyle', {color});

          // If we found any fully selected list items, apply color to them
          if (listItems.some((item) => item.fullySelected)) {
            return colorChain
              .command(({tr}) => {
                // Apply color only to fully selected list items
                listItems.forEach(({pos, node, fullySelected}) => {
                  if (fullySelected) {
                    tr.setNodeMarkup(pos, undefined, {
                      ...node.attrs,
                      textColor: color,
                    });
                  }
                });

                // For fully selected ordered lists, apply color to the list too
                orderedLists.forEach(({pos, node}) => {
                  // Check if all children of this list are fully selected
                  const nodeStart = pos;
                  const nodeEnd = pos + node.nodeSize;
                  const listFullySelected = $from.pos <= nodeStart && $to.pos >= nodeEnd;

                  if (listFullySelected) {
                    tr.setNodeMarkup(pos, undefined, {
                      ...node.attrs,
                      textColor: color,
                    });
                  }
                });

                return true;
              })
              .run();
          }

          return colorChain.run();
        },

      // Add unsetColor command to also clear list marker colors
      unsetColor:
        () =>
        ({chain, state}) => {
          const {selection} = state;
          const {$from, $to} = selection;

          // Find all ordered lists and list items in the selection
          const orderedLists: {pos: number; node: any}[] = [];
          const listItems: {pos: number; node: any; fullySelected: boolean}[] = [];

          // Check if we have ordered lists within our selection
          state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
            if (node.type.name === 'orderedList') {
              // Store the list for later processing
              orderedLists.push({pos, node});
            }
            if (node.type.name === 'listItem') {
              // Check if the entire list item is selected
              const nodeStart = pos;
              const nodeEnd = pos + node.nodeSize;
              const fullySelected = $from.pos <= nodeStart && $to.pos >= nodeEnd;

              // Store list item with selection status
              listItems.push({pos, node, fullySelected});
            }
            // Continue traversing
            return true;
          });

          // First remove the color from the text
          const colorChain = chain().setMark('textStyle', {color: null}).removeEmptyTextStyle();

          // If we found any fully selected list items, remove color from them
          if (listItems.some((item) => item.fullySelected)) {
            return colorChain
              .command(({tr}) => {
                // Remove color only from fully selected list items
                listItems.forEach(({pos, node, fullySelected}) => {
                  if (fullySelected && node.attrs.textColor) {
                    tr.setNodeMarkup(pos, undefined, {
                      ...node.attrs,
                      textColor: null,
                    });
                  }
                });

                // For fully selected ordered lists, remove color from the list too
                orderedLists.forEach(({pos, node}) => {
                  // Check if all children of this list are fully selected
                  const nodeStart = pos;
                  const nodeEnd = pos + node.nodeSize;
                  const listFullySelected = $from.pos <= nodeStart && $to.pos >= nodeEnd;

                  if (listFullySelected && node.attrs.textColor) {
                    tr.setNodeMarkup(pos, undefined, {
                      ...node.attrs,
                      textColor: null,
                    });
                  }
                });

                return true;
              })
              .run();
          }

          return colorChain.run();
        },
    };
  },
});

// Types affected by keepClasses, which should be all
const keepClassesTypes = [
  // Nodes
  'doc',
  'paragraph',
  'heading',
  'orderedList',
  'bulletList',
  'listItem',
  // Marks
  'bold',
  'italic',
  'strike',
  'link',
  'color',
  'highlight',
  'textStyle',
  'underline',
];

// By default, all classes are wiped out when tiptap is loaded. This keeps classes, so any custom classes set by us or tiptap are preserved
export const KeepClasses = Extension.create({
  name: 'keepClasses',

  addGlobalAttributes() {
    return [
      {
        types: keepClassesTypes,
        attributes: {
          class: {
            default: null,
            parseHTML: (element) => element.getAttribute('class'),
            renderHTML: (attributes) => {
              if (!attributes.class) {
                return {};
              }
              return {
                class: attributes.class,
              };
            },
          },
        },
      },
    ];
  },
});

// Custom text align extension to remove Quill's text align classes when a new alignment is set
export const CustomTextAlign = TextAlign.extend({
  // Remove the Quill classes when we set one here
  addCommands() {
    return {
      setTextAlign:
        (alignment) =>
        ({commands}) => {
          return commands.command(({tr, state}) => {
            const {selection} = state;
            const {ranges} = selection;

            ranges.forEach((range) => {
              state.doc.nodesBetween(range.$from.pos, range.$to.pos, (node, pos) => {
                if (node.type.isTextblock) {
                  const nodeAttrs = {
                    ...node.attrs,
                    textAlign: alignment,
                    class: removeQuillAlignClasses(node.attrs.class),
                  };

                  tr.setNodeMarkup(pos, undefined, nodeAttrs);
                }
              });
            });

            return true;
          });
        },
    };
  },
  // Make quill respect the old align attributes
  addAttributes() {
    return {
      textAlign: {
        default: null,
        parseHTML: (element: HTMLElement) => {
          const textAlign = element.style.textAlign || null;

          // Check for ql-align-* classes
          const {classList} = element;
          if (classList) {
            for (const className of classList) {
              if (className.startsWith('ql-align-')) {
                const align = className.replace('ql-align-', '');
                return align;
              }
            }
          }

          return textAlign;
        },
        renderHTML: (attributes: {[attribute: string]: unknown}) => {
          if (!attributes.textAlign) {
            return {};
          }
          return {
            style: `text-align: ${attributes.textAlign}`,
          };
        },
      },
    };
  },
});

function removeQuillAlignClasses(classAttr: {[attribute: string]: any}) {
  if (!classAttr) {
    return;
  }

  // Remove any ql-align-* classes
  const classes = classAttr
    .split(' ')
    .filter((className: string) => !className.startsWith('ql-align-'));

  return classes.length > 0 ? classes.join(' ') : null;
}

// Adds color tracking for ordered lists
export const UpdateListColor = Extension.create({
  name: 'updateListColor',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          // If no transactions or they don't change the doc, don't do anything
          if (!transactions.some((tr) => tr.docChanged)) {
            return null;
          }

          let {tr} = newState;
          let modified = false;

          // Iterate through all ordered list nodes in the document
          newState.doc.descendants((node, pos) => {
            if (node.type.name === 'orderedList') {
              // Track the list items by position
              const listItems: {pos: number; node: any}[] = [];

              // Track items that should get colored
              const itemsToColor: {pos: number; node: any; color: string}[] = [];

              // Process each list item individually
              node.content.forEach((listItem, offset) => {
                const itemPos = pos + 1 + offset;
                listItems.push({pos: itemPos, node: listItem});

                // Check if this list item's content is entirely the same color
                let itemColor: string | null = null;
                let itemTotalContent = 0;
                let itemColoredContent = 0;
                let itemAllSameColor = true;

                // Look at text marks in each content node of the list item
                listItem.content.forEach((paragraph) => {
                  paragraph.content.forEach((textNode) => {
                    if (textNode.text && textNode.text.trim().length > 0) {
                      itemTotalContent += textNode.text.length;

                      // Look for 'textStyle' mark with color attribute
                      const textStyleMark = textNode.marks.find(
                        (mark) => mark.type.name === 'textStyle',
                      );

                      const currentColor = textStyleMark?.attrs?.color || null;

                      // Track how much content has color and if it's consistent
                      if (currentColor) {
                        itemColoredContent += textNode.text.length;

                        // Initialize itemColor with the first color found
                        if (itemColor === null) {
                          itemColor = currentColor;
                        }
                        // If a different color is found, not all text has the same color
                        else if (currentColor !== itemColor) {
                          itemAllSameColor = false;
                        }
                      } else {
                        // Found text with no color - not fully colored
                        itemAllSameColor = false;
                      }
                    }
                  });
                });

                // Only color the list item's marker if ALL content has the SAME color
                const itemFullyColored =
                  itemTotalContent > 0 &&
                  itemColoredContent === itemTotalContent &&
                  itemAllSameColor;

                // If this item should be colored and isn't already the right color
                if (itemFullyColored && itemColor && listItem.attrs.textColor !== itemColor) {
                  itemsToColor.push({pos: itemPos, node: listItem, color: itemColor});
                }
                // If this item shouldn't be colored but has a color
                else if (!itemFullyColored && listItem.attrs.textColor) {
                  // Remove color from the item
                  tr = tr.setNodeMarkup(itemPos, undefined, {
                    ...listItem.attrs,
                    textColor: null,
                  });
                  modified = true;
                }
              });

              // Apply colors to list items that need it
              if (itemsToColor.length > 0) {
                itemsToColor.forEach(({pos, node, color}) => {
                  tr = tr.setNodeMarkup(pos, undefined, {
                    ...node.attrs,
                    textColor: color,
                  });
                  modified = true;
                });

                // Only color the overall list if ALL items have the SAME color
                if (itemsToColor.length === node.content.childCount) {
                  // Check if all items have the same color
                  const firstColor = itemsToColor[0].color;
                  const allSameColor = itemsToColor.every((item) => item.color === firstColor);

                  if (allSameColor) {
                    tr = tr.setNodeMarkup(pos, undefined, {
                      ...node.attrs,
                      textColor: firstColor,
                    });
                    modified = true;
                  }
                } else if (node.attrs.textColor) {
                  // If not all items have the same color, remove color from the list
                  tr = tr.setNodeMarkup(pos, undefined, {
                    ...node.attrs,
                    textColor: null,
                  });
                  modified = true;
                }
              } else if (node.attrs.textColor) {
                // If no items should be colored, remove the color from the list
                tr = tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  textColor: null,
                });
                modified = true;
              }
            }
          });

          if (modified) {
            return tr;
          }
          return null;
        },
      }),
    ];
  },
});

export const UpdateListNesting = Extension.create({
  name: 'updateListNesting',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          let {tr} = newState;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (node.type.name === 'orderedList') {
              // Start with level 1 for top-level lists
              let level = 1;

              // Resolve the position to get a ResolvedPos object
              const $pos = tr.doc.resolve(pos);

              // Iterate over the depth to calculate nesting level
              for (let d = $pos.depth - 1; d >= 0; d -= 1) {
                const parentNode = $pos.node(d);
                if (parentNode.type.name === 'orderedList') {
                  level += 1;
                }
              }

              if (node.attrs.dataLevel !== level) {
                tr = tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  dataLevel: level,
                });
                modified = true;
              }
            }
          });

          if (modified) {
            return tr;
          }
          return null;
        },
      }),
    ];
  },
});
