// Libraries
import {DndContext, DragOverlay, closestCenter} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React from 'react';
import {createPortal} from 'react-dom';

// Supermove
import {ViewStyleProp} from '@supermove/styles/types';

// Relative
import DragAndDropListV1 from './DragAndDropListV1';
import DragAndDropBuilder, {OnReorderType} from './components/DragAndDropBuilder';
import DragComponent from './components/DragComponent';
import useDndKit from './hooks/useDndKit';

const DraggableWrapper = ({
  id,
  isDragging,
  isDragDisabled,
  isDraggable,
  children,
}: {
  id: string;
  isDragging: boolean;
  isDragDisabled: boolean;
  isDraggable?: boolean;
  children: React.ReactNode;
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id,
    disabled: isDragDisabled,
  });

  if (isDraggable) {
    return (
      <div
        {...attributes}
        {...listeners}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
          touchAction: 'none',
          userSelect: 'none',
          WebkitUserSelect: 'none',
        }}
      >
        {children}
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const SortableItem = ({
  id,
  index,
  spaceBetweenItems,
  isHorizontal,
  isDragging,
  isDragIconHidden,
  isDragDisabled,
  itemContainerStyle,
  children,
}: {
  id: string;
  index: number;
  spaceBetweenItems: number;
  isHorizontal?: boolean;
  isDragging: boolean;
  isDragIconHidden: boolean;
  isDragDisabled: boolean;
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactNode;
}) => {
  const {setNodeRef, transform, transition} = useSortable({
    id,
    disabled: isDragDisabled,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.2 : 1,
      }}
    >
      <DraggableWrapper
        id={id}
        isDragging={isDragging}
        isDragDisabled={isDragDisabled}
        isDraggable={isHorizontal}
      >
        <DragAndDropBuilder.ItemContainer
          index={index}
          spaceBetweenItems={spaceBetweenItems}
          isHorizontal={isHorizontal}
          style={itemContainerStyle}
        >
          {!isDragIconHidden && (
            <DraggableWrapper
              id={id}
              isDragging={isDragging}
              isDragDisabled={isDragDisabled}
              isDraggable={!isHorizontal}
            >
              <DragAndDropBuilder.DragIcon isDisabled={isDragDisabled} />
            </DraggableWrapper>
          )}
          {children}
        </DragAndDropBuilder.ItemContainer>
      </DraggableWrapper>
    </div>
  );
};

const DragAndDropListV2 = ({
  scrollRef,
  spaceBetweenItems = 0,
  onReorder = () => {},
  isHorizontal = false,
  isDisabled = false,
  isDisabledWithVisibleIcons = false,
  disabledIndexes = [],
  itemContainerStyle,
  children,
}: {
  scrollRef?: React.RefObject<HTMLDivElement>;
  spaceBetweenItems?: number;
  onReorder?: OnReorderType;
  isHorizontal?: boolean;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  disabledIndexes?: number[];
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactElement[];
}) => {
  const {
    childrenWithIds,
    itemIds,
    isDragging,
    isDraggingId,
    setIsDraggingId,
    handleDragEnd,
    sensors,
  } = useDndKit({onReorder, children});

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragPending={({id}) => setIsDraggingId(id)}
      onDragAbort={() => setIsDraggingId(undefined)}
      onDragEnd={(event) => handleDragEnd(event)}
      autoScroll={{enabled: false}}
    >
      <div
        ref={scrollRef}
        style={{
          flexDirection: isHorizontal ? 'row' : 'column',
          overflowY: 'visible',
          marginLeft: isHorizontal ? -spaceBetweenItems / 2 : 0,
          marginRight: isHorizontal ? -spaceBetweenItems / 2 : 0,
          marginTop: isHorizontal ? 0 : -spaceBetweenItems / 2,
          marginBottom: isHorizontal ? 0 : -spaceBetweenItems / 2,
        }}
      >
        <SortableContext
          items={itemIds}
          strategy={isHorizontal ? horizontalListSortingStrategy : verticalListSortingStrategy}
        >
          {React.Children.map(childrenWithIds, (item, index) => {
            const itemId = item.props.id;
            const isDraggingThisItem = isDragging && isDraggingId === itemId;
            const isDisabledIndex = disabledIndexes.includes(index);

            return (
              <SortableItem
                key={item.key}
                id={itemId}
                index={index}
                isHorizontal={isHorizontal}
                isDragging={isDraggingThisItem}
                isDragIconHidden={isDisabled || isDisabledIndex || isHorizontal}
                isDragDisabled={isDisabled || isDisabledIndex || isDisabledWithVisibleIcons}
                spaceBetweenItems={spaceBetweenItems}
                itemContainerStyle={itemContainerStyle}
              >
                {item}
              </SortableItem>
            );
          })}
        </SortableContext>
      </div>

      {createPortal(
        <DragOverlay style={{cursor: isDragging ? 'grabbing' : 'grab'}}>
          {isDragging && (
            <DragComponent
              spaceBetweenItems={spaceBetweenItems}
              isDisabled={isDisabled}
              disabledIndexes={disabledIndexes}
              itemContainerStyle={itemContainerStyle}
              isDraggingId={isDraggingId}
              isHorizontal={isHorizontal}
              childrenWithIds={childrenWithIds}
            />
          )}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};

const DragAndDropList = ({
  scrollRef,
  indexOfEdit,
  spaceBetweenItems = 0,
  onReorder = () => {},
  isReordering = false,
  isDisabled = false,
  isDisabledWithVisibleIcons = false,
  disabledIndexes = [],
  droppableStyle,
  itemContainerStyle,
  children,
  isDragAndDropListV1 = false,
  isHorizontal = false,
}: {
  scrollRef?: React.RefObject<HTMLDivElement>;
  indexOfEdit?: number | null;
  spaceBetweenItems?: number;
  onReorder?: OnReorderType;
  isReordering?: boolean;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  disabledIndexes?: number[];
  droppableStyle?: ViewStyleProp;
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactElement[];
  isDragAndDropListV1?: boolean;
  isHorizontal?: boolean;
}) => {
  if (isDragAndDropListV1) {
    return (
      <DragAndDropListV1
        scrollRef={scrollRef}
        indexOfEdit={indexOfEdit}
        spaceBetweenItems={spaceBetweenItems}
        onReorder={onReorder}
        isReordering={isReordering}
        isDisabled={isDisabled}
        isDisabledWithVisibleIcons={isDisabledWithVisibleIcons}
        disabledIndexes={disabledIndexes}
        // @ts-expect-error using ViewStyleProp but expecting CSSProperties
        droppableStyle={droppableStyle}
        // @ts-expect-error using ViewStyleProp but expecting CSSProperties
        itemContainerStyle={itemContainerStyle}
      >
        {children}
      </DragAndDropListV1>
    );
  }
  return (
    <DragAndDropListV2
      scrollRef={scrollRef}
      spaceBetweenItems={spaceBetweenItems}
      onReorder={onReorder}
      isHorizontal={isHorizontal}
      isDisabled={isDisabled}
      isDisabledWithVisibleIcons={isDisabledWithVisibleIcons}
      disabledIndexes={disabledIndexes}
      itemContainerStyle={itemContainerStyle}
    >
      {children}
    </DragAndDropListV2>
  );
};

export default DragAndDropList;
