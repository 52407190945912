// Libraries
// @ts-expect-error library is not typed
import {Loading} from '@increment/components';
import React from 'react';

// Supermove
import {gql, RestContext} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Shared
import Query from '../Query';
import Styled from '../Styled';

import {UpdateCheckerProps} from './UpdateCheckerTypes';

const Indicator = Styled.Loading`
  margin-top: 20px;
`;

// UpdateChecker for web checks against a /version.json file generated at build-time, instead of
// hitting our API
const UpdateChecker = ({
  showLoading = false,
  name,
  buildNumber,
  pollInterval,
  children,
}: UpdateCheckerProps) => {
  const [isSkipped, setIsSkipped] = useState(false);

  return (
    <Query
      fetchPolicy={'network-only'}
      pollInterval={pollInterval}
      query={UpdateChecker.query}
      context={RestContext}
    >
      {({loading, data, error}: {loading: boolean; data: any; error: unknown}) => (
        <Loading
          loading={loading}
          as={showLoading ? <Indicator size={'small'} color={colors.gray.secondary} /> : null}
        >
          {() => {
            let isVisible = false;
            try {
              if (data) {
                isVisible = Number(data.version.app_build) - Number(buildNumber) >= 3;
              }
            } catch (e) {
              console.error('Error parsing version data');
            }
            return children({
              isSkipped,
              isVisible,
              isRequired: false,
              onSkip: () => setIsSkipped(true),
            });
          }}
        </Loading>
      )}
    </Query>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateChecker.query = gql`
  query UpdateCheckerWeb {
    version @rest(type: "Version", path: "version.json", endpoint: "static") {
      app_build
      app_version
      commit
    }
  }
`;

export default UpdateChecker;
