// Libraries
import {FormikValues} from 'formik';
import {DocumentNode} from 'graphql';
import {BaseMutationOptions, OperationVariables, MutationTuple} from 'react-apollo';

import {useFormErrors, useMutation, useMutationCallbacks} from '@supermove/hooks';

import {Form, MutationResponse, MutationError} from './types';

type Args<FormValues extends FormikValues, Results, Variables> = {
  form: Form<FormValues>;
  mutation: DocumentNode;
  refetchQueries?: BaseMutationOptions['refetchQueries'];
  variables: Variables;
  onSuccess?: (results: Results) => void;
  onError?: (errors: MutationError[]) => void;
  client?: BaseMutationOptions['client'];
};

const useFormMutation = <
  FormValues extends FormikValues = Record<string, any>,
  Results extends Record<string, any> = any,
  Variables = OperationVariables,
>({
  form,
  mutation,
  refetchQueries,
  variables,
  onSuccess,
  onError,
  client,
}: Args<FormValues, Results, Variables>): {
  submitting: boolean;
  handleSubmit: MutationTuple<MutationResponse<Results>, Variables>[0];
} => {
  const [handleSubmit, {loading, data, error}] = useMutation(mutation, {
    refetchQueries,
    variables,
    client,
  });
  // Trigger validation errors on the form.
  useFormErrors({form, data});
  // Trigger callbacks when the data field changes.
  // @ts-expect-error the react-apollo library is on a different version than our client library, so the typing is wrong.
  useMutationCallbacks({data, onSuccess, onError, error});

  return {
    submitting: loading,
    handleSubmit,
  };
};

export default useFormMutation;
